$(function() {
  $('.brands__slides').owlCarousel({
    items: 1,
    loop: true,
    nav: true,
    navContainerClass: ['slider-nav', 'slider-nav--brands'],
    navClass: ['slider__btn slider__btn--prev', 'slider__btn slider__btn--next'],
    responsive: {
      768: {
        items: 3
      },
      1140: {
        items: 6
      }
    }
  });

  $('.sections__slides').owlCarousel({
    items: 1,
    loop: true,
    nav: true,
    navContainerClass: ['slider-nav', 'slider-nav--sections'],
    navClass: ['slider__btn slider__btn--prev', 'slider__btn slider__btn--next'],
    responsive: {
      768: {
        items: 3
      },
      1140: {
        items: 4
      }
    }
  });

  // $(".slider__btn--prev").click(function(){
  //   $('.brands__slides').trigger("next.owl.carousel");
  // });

  // $(".slider__btn--next").click(function(){
  //   $('.brands__slides').trigger("prev.owl.carousel");
  // });
});
// $(document).ready(function(){
// $(".site-list").on("click","a", function (event) {
//  event.preventDefault();
//  var id  = $(this).attr('href'),
//      top = $(id).offset().top;
//  $('body,html').animate({scrollTop: top}, 1500);
// });
